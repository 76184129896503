import React from 'react';

import { getAuthdClientInstance } from "../client";

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

export default class Uploader extends React.Component {

  async getUploadParams({ file, meta: { name } }) {
    const s3Key = `${+Date.now()}-${file.name}`
    const resp = await getAuthdClientInstance().getPresignedUrl(s3Key);
    return {
      body: file,
      method: "PUT",
      headers: {
        "x-amz-acl": "public-read"
      },
      meta: {
        fileUrl: resp.data.publicUrl,
        s3Key: resp.data.s3Key,
        s3Bucket: resp.data.s3Bucket,
      },
      url: resp.data.signedUrl
    }
  }

  render() {
    return (<Dropzone
      getUploadParams={this.getUploadParams.bind(this)}
      onChangeStatus={this.props.handleChangeStatus}
      submitButtonContent=""
      onSubmit={this.props.onSubmit}
      accept="image/*,video/*"
      inputContent={"Attach Some Photos!"}
    />)
  }
}
import React from "react"
import { useMediaQuery, Fab, Grid, Card, Typography, CardHeader, CardContent, Dialog, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from '@material-ui/core/styles';
import { formatDateString } from "../../utils";

import "./styles.css";

export default (props) =>  {

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const classes = {}
  if (fullScreen) {
    classes.paperScrollPaper = "FullScreenRoot";
  }

  return <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen={fullScreen}
      fullWidth
      classes={classes}
      maxWidth="sm">
        <div className={"Close"}>
          <Fab color="primary" onClick={props.onClose}>
            <CloseIcon />
          </Fab>
        </div>



        <Grid container
              direction="column">
          <Grid item>
            <Card classes={{ "root": "ScrollCard"}}>
              <CardHeader
                title={"Comments"}
                subheader={"ordered by recency"}
              ></CardHeader>
              <CardContent>
                <Grid container direction={"column"} spacing={2}>
                  {props.comments.map(c => {
                    return (<><Grid item>
                                <Typography gutterBottom gutterTop>
                                  {c.Content}
                                </Typography>
                              </Grid>
                              <Grid item container direction="row" justify="flex-end">
                                <Typography gutterBottom gutterTop>
                                  - {c.Author} @ {formatDateString(c.CreatedAt)}
                                </Typography>
                              </Grid>
                              <Divider />
                            </>)
                  })}
                </Grid>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
    </Dialog>
}
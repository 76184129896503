
const LOCAL_STORAGE_USERNAME_KEY = "username";
const LOCAL_STORAGE_PASSWORD_KEY = "password";

/**
 * Given a username and password, saves them to local storage
 * @param {*} username
 * @param {*} password
 */
export const persistCredentials = (username, password) => {
  localStorage.setItem(LOCAL_STORAGE_USERNAME_KEY, username);
  localStorage.setItem(LOCAL_STORAGE_PASSWORD_KEY, password);
}

/**
 * Retrieves the currently set username and password from local
 * storage
 */
export const retrieveCredentials = () => {
  if (localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY) === null) {
    throw new Error("Nothing Found in Local Storage");
  } else {
    return {
      username: localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY),
      password: localStorage.getItem(LOCAL_STORAGE_PASSWORD_KEY),
    };
  }

}

/**
 * Clears the currently persisted credentials from local
 * storage
 */
export const clearCredentials = () => {
  localStorage.removeItem(LOCAL_STORAGE_USERNAME_KEY);
  localStorage.removeItem(LOCAL_STORAGE_PASSWORD_KEY);
}

/**
 * Returns a boolean indicating whether or not the current user
 * is logged in
 */
export const isLoggedIn = () => {
  try {
    const { username, password } = retrieveCredentials();
    return username && password
  } catch(e){
    return false;
  }
}
import React from "react";

import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import "./styles.css";

export default class AddPostButton extends React.Component {

  render() {
    return (
      <div className={"AddPostButton"}>
        <Fab color="primary" onClick={this.props.onClick}>
          <AddIcon />
        </Fab>
      </div>
    );
  }
}
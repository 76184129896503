import React from "react";

import { Card,
         CardContent,
         CardHeader } from "@material-ui/core";

import { Avatar } from "@material-ui/core";


import "./styles.css";
import CardPhoto from "./components/CardPhoto";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { isPhotoVideo, formatDateString } from "../../utils";
import MessageContents from "./components/MessageContents";

export default class PostCard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      menuAnchorElement: null,
      lightBoxOpen: false,
      photoIndex: 0,
      post: props.post,
    };
  }

  handleMenuClick(event) {
    this.setState({ menuAnchorElement: event.currentTarget })
  }

  handleMenuClose() {
    this.setState({menuAnchorElement: undefined});
  }

  handleDelete() {
    this.props.handleDelete(this.props.post.ID);
    this.handleMenuClose();
  }

  getDateString() {
    return formatDateString(this.props.post.CreatedAt);
  }

  handlePhotoClick(photo) {
    this.setState({
      lightBoxOpen: true,
      photoIndex: this.props.post.Photos.findIndex(p => p.ID === photo.ID),
    })
    this.props.onLightBoxOpen()
  }

  handleLightBoxClose() {
    this.setState({ lightBoxOpen: false })
    this.props.onLightBoxClose()
  }

  render() {

    const photosPresent = this.props.post.Photos.length > 0;

    const shouldShowSpacer = !photosPresent;
    const { photoIndex, lightBoxOpen } = this.state;

    if (lightBoxOpen) {
      const images = this.props.post.Photos.filter(
        p => !isPhotoVideo(p)
      ).map(p => p.PublicURL);

      return (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 1500,
            }
          }}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={this.handleLightBoxClose.bind(this)}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      )
    } else {

      return (
        <Card classes={{ "root": "ScrollCard"}}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="recipe"
                src={this.props.post.User.avatar_url}
              ></Avatar>
            }
            subheader={this.getDateString()}
          />
          <CardContent>
            <MessageContents
              onEmojiUpdate={this.props.onEmojiUpdate}
              useSpacer={shouldShowSpacer}
              post={this.props.post}
            ></MessageContents>

            {this.props.post.Photos.map(p => 
              <CardPhoto photo={p}
                         onClick={this.handlePhotoClick.bind(this)}
                         onDelete={this.props.handlePhotoDelete.bind(this)}
                         shouldShowDeleteButtons={this.props.shouldShowDeleteButtons} />)
            }
          </CardContent>
        </Card>
      )
    }
  }
}

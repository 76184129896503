import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

import "./styles.css";
import { Drawer, Divider, Grid } from '@material-ui/core';

export default function ViewControls(props) {
  
  return (
    <Drawer anchor="left" variant="temporary" open={props.open} onClose={props.onClose}>
      <Grid container classes={{
        root: "DrawerRoot"
      }}>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Show posts from</FormLabel>
            <FormGroup>
              {Object.keys(props.users).map((u) => {
                const userChecked = props.users[u];
                return <FormControlLabel
                  key={u}
                  control={<Switch checked={userChecked}
                  onChange={props.handleUserChange}
                  color={"primary"}
                  name={u} />}
                  label={u}
                />
              })}
            </FormGroup>
            <Divider></Divider>
            <FormControlLabel
              control={<Switch checked={props.shouldCluster}
              onChange={props.handleClusterChange}
              color={"primary"}/>}
              label="Cluster Markers"
            />
            {props.showLoggedInControls ? <FormControlLabel
              control={
                <Switch
                  checked={props.canDrag}
                  onChange={props.handleDragToggleChange}             
                  color="primary"
                />
              }
              label="Drag Markers"
            /> : ""}

            {props.showLoggedInControls ? <FormControlLabel
              control={
                <Switch
                  checked={props.shouldShowDeleteButtons}
                  onChange={props.handleShouldShowDeleteChange}             
                  color="primary"
                />
              }
              label="Show Delete Buttons"
            /> : ""}
          </FormControl>
        </Grid>
      </Grid>
    </Drawer>
  );
}

import React, { Component } from 'react';

import { Dialog,
         DialogTitle,
         DialogContent,
         TextField,
         Button,
         DialogActions,
         Grid,
         CircularProgress } from "@material-ui/core";

import { getPublicClientInstance } from '../../client';

import "./styles.css";

export default class CommentCreationDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: "",
      author: "",
    }
  }
  clearFields() {
    this.setState({
      message: "",
      author: "",
    });
  }

  // receives array of files that are done uploading when submit button is clicked
  async handleSubmit() {
    this.setState({
      submitInProgress: true
    });
    const client = getPublicClientInstance();
    try {
      await client.createComment(this.state.message, this.state.author);
    } catch(e){
      this.props.handleNetworkError(e);
      this.setState({ submitInProgress: false })
      return;
    }
    this.props.handleSubmit();
    this.handleClose();
  }

  handleMessageChange(changeEvent) {
    const msg = changeEvent.target.value;
    this.setState({
      message: msg,
    });
  }

  handleAuthorChange(changeEvent) {
    const author = changeEvent.target.value;
    this.setState({
      author: author,
    });
  }

  canSubmit() {
    return this.state.message.length > 0 && this.state.author.length > 0;
  }

  submitButtonText() {
    if (this.state.submitInProgress) {
      return "Submitting...";
    } else {
      return "Submit";
    }
  }

  async handleClose() {
    this.clearFields();
    this.props.onClose();
    this.setState( {submitInProgress: false} );
  }

  render() {

    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="lg">
        <DialogTitle>Write a comment!</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            direction="column">
            <Grid item>
              <TextField
                multiline
                fullWidth
                placeholder="Write a quick message"
                label="Message body"
                variant="filled"
                onChange={this.handleMessageChange.bind(this)}></TextField>
            </Grid>
            <Grid item>
              <TextField
                multiline
                fullWidth
                placeholder="Who are you?"
                label="Author"
                variant="filled"
                onChange={this.handleAuthorChange.bind(this)}></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>CANCEL</Button>
          <div className={"SubmitWrapper"}>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.canSubmit()}
              onClick={this.handleSubmit.bind(this)}
            >
              {this.submitButtonText()}
            </Button>
            {this.state.submitInProgress && <CircularProgress size={24} className={"SubmitProgress"} />}
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}
import axios from "axios";

/**
 * A client for interacting with the subset of the API
 * which does not require credentials
 */
export default class PublicClient {

  /**
   * Lists all the posts made so far
   */
  async listPosts() {
    return await axios.get("/api/posts");
  }

  /**
   * Retrieve a single post
   *
   * @param {*} postId
   */
  async getPost(postId) {
    return (await axios.get(`/api/posts/${postId}`)).data.post;
  }

  /**
   * Lists users available on the site
   */
  async listUsers() {
    return (await axios.get(`/api/users`)).data.users;
  }

  /**
   * Updates a post, but only with new values for tmoji counts
   *
   * @param {*} updateParameters - these can be any subset of the following:
   * {
   *  PoopCount: int,
   *  WowCount: int
   *  BikeCount: int
   *  DisappointedCount: int
   *  BabyBottleCount: int
   *  HeartCount: int
   * }
   */
  async updatePost(postId, updateParameters) {
    return await axios.patch(`/api/posts/${postId}`, updateParameters);
  }

  async createComment(message, author) {
    return (await axios.post("/api/comments", {
      content: message,
      author,
    })).data.comment;
  }
}
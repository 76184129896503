import React from "react";

import { Fab } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import "./styles.css";

export default class AddPostButton extends React.Component {

  render() {
    return (
      <div className={"AddCommentButton"}>
        <Fab color="primary" onClick={this.props.onClick}>
          <EditIcon />
        </Fab>
      </div>
    );
  }
}
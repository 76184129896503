import axios from "axios";

/**
 * A simple HTTP client for interacting with the subset of the
 * API which requires authentication credentials
 */
export default class AuthClient {

  constructor(username, password) {
    this.axiosBasicAuth = {
      username,
      password,
    };
  }

  /**
   * Creates a new post, with a message at the given lat and long
   *
   * @param {*} message
   * @param {*} lat
   * @param {*} long
   */
  async createPost(message, lat, long) {
    return await axios.post("/api/posts", {
      message,
      lat,
      long,
    }, {
      auth: this.axiosBasicAuth,
    });
  }

  /**
   * Updates a post, with a message at the given lat and long
   *
   * @param {*} updateParameters - these can be any subset of the following:
   * {
   *  message: string,
   *  lat: float,
   *  long: float,
   *  PoopCount: int,
   *  WowCount: int
   *  BikeCount: int
   *  DisappointedCount: int
   *  BabyBottleCount: int
   *  HeartCount: int
   * }
   */
  async updatePost(postId, updateParameters) {
    return await axios.patch(`/api/posts/${postId}`, updateParameters, {
      auth: this.axiosBasicAuth,
    });
  }

  /**
   * Deletes the post with the provided ID
   * @param {*} postId
   */
  async deletePost(postId) {
    return await axios.delete(`/api/posts/${postId}`, {
      auth: this.axiosBasicAuth,
    })
  }

  /**
   * Retrieves a presigned URL for uploading to S3
   */
  async getPresignedUrl(objectKey) {
    return await axios.get(`/api/s3-presigned-url?object_key=${objectKey}`, {
      auth: this.axiosBasicAuth,
    });
  }

  /**
   * Creates a photo for the post with the ID provided
   */
  async createPhoto(postId, s3Key, s3Bucket) {
    return await axios.post(`/api/posts/${postId}/photos`, {
      s3Key,
      s3Bucket,
    },{
      auth: this.axiosBasicAuth,
    });
  }

  /**
   * Deletes a photo from the specified post
   */
  async deletePhoto(postID, photoID) {
    return await axios.delete(`/api/posts/${postID}/photos/${photoID}`, {
      auth: this.axiosBasicAuth,
    })
  }

  /**
   * Retrieves the currently logged in user
   */
  async me() {
    return await axios.get(`/api/me`, {
      auth: this.axiosBasicAuth,
    });
  }

  async listComments() {
    return (await axios.get("/api/comments", {
      auth: this.axiosBasicAuth,
    })).data.comments;
  }
}
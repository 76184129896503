import React from "react";
import "./styles.css";

import LandscapeIcon from '@material-ui/icons/Landscape';
import { Fab, Menu } from "@material-ui/core";

export default class MainMenu extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    };
  }

  handleMenuClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({anchorEl: undefined});
  }

  render() {

    const fabIcon = this.props.icon ? this.props.icon : <LandscapeIcon />
    return (
      <div className={"MenuWrapper"}>
        <Fab
          onClick={this.handleMenuClick.bind(this)}>
          {fabIcon}
        </Fab>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          onClick={this.handleClose.bind(this)}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose.bind(this)}
        >
          {this.props.children}
        </Menu>
      </div>
    )
  }
}

import React from "react";
import { Avatar } from "@material-ui/core";
import "./styles.css";

export default class CurrentUserAvatar extends React.Component {

  render() {
    return (
      <Avatar
        className={"SizedAvatar"}
        src={this.props.user.avatar_url}
      ></Avatar>
    )
  }
}
import React, { Component } from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api'
import { getCurrentPosition } from './utils'
import { getAuthdClientInstance } from './client'


const options = {
  imagePath: "https://i.imgur.com/4SaAL70.png"
}

class MapContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      posts: [],
      mapZoom: 6,
    };
  }

  async componentDidMount() {
    if (this.props.askForLocation) {
      const { lat, long } = await getCurrentPosition();
      this.setState({
        currentPositionLat: lat,
        currentPositionLong: long
      });
    }
  }

 async onMarkerDragEnd(e, post) {
    console.log(e.latLng.lat())
    console.log(e.latLng.lng())
    let client = getAuthdClientInstance()
    try {
      let resp = await client.updatePost(post.ID, {lat: e.latLng.lat(), long: e.latLng.lng()})
      post = resp.data.post
    } catch (e) {
      this.setState({updateError: e})
    }
    this.setState({
      centerLat: e.latLng.lat(),
      centerLong: e.latLng.lng(),
    });
    this.props.onDrag(post)
  }

  canDragMarker(post) {
    return this.props.canDrag && this.props.currentUser && this.props.currentUser.ID === post.User.ID;
  }

  onMarkerClick(post) {
    this.setState({
      centerLat: post.lat,
      centerLong: post.long,
    })
    this.props.onMarkerClick(post);
  }

  render() {
    const locationMarker = this.props.askForLocation ? <Marker position={{lat: this.state.currentPositionLat, lng: this.state.currentPositionLong}}
                  icon={{url: "https://imgur.com/rzEMZDR.png",
                        origin: new window.google.maps.Point(0, 0),
                        anchor:  new window.google.maps.Point(0, 0),
                        scaledSize:  new window.google.maps.Size(15, 15)}}
                  visible={this.props.showMarker}
                  clickable={false}
          /> : ""

    const finalPost = this.props.posts ? this.props.posts.slice(this.props.posts.length - 1)[0] : undefined;
    const otherPosts = this.props.posts ? this.props.posts.slice(0, this.props.posts.length - 1) : [];
    const markers = this.props.shouldCluster ?
      <MarkerClusterer options={options} maxZoom={17} >

        {clusterer=>
          otherPosts.map((post) => {
            return (
              <Marker
                label={
                  {text: post.User.full_name.split(" ")[0].split("")[0] + post.User.full_name.split(" ")[1].split("")[0],
                  color: "White"}}
                position={{lat: post.lat, lng: post.long}}
                key={post.ID}
                clusterer={clusterer}
                onClick={this.onMarkerClick.bind(this, post)}
                draggable={this.canDragMarker(post)}
                onDragEnd={(e) => this.onMarkerDragEnd(e, post)}
              >
              </Marker>);
          })
        }
      </MarkerClusterer> : (
        <>
          {otherPosts.map((post, idx) => {
            return (
              <Marker
                label={
                  {text: post.User.full_name.split(" ")[0].split("")[0] + post.User.full_name.split(" ")[1].split("")[0],
                  color: "White"}}
                position={{lat: post.lat, lng: post.long}}
                key={post.ID}
                onClick={this.props.onMarkerClick.bind(this, post, idx)}
                draggable={this.canDragMarker(post)}
                onDragEnd={(e) => this.onMarkerDragEnd(e, post)}
              >
              </Marker>);
          })}
        </>
      )

    const bikeIconUrl = "https://along-bike.s3-us-west-2.amazonaws.com/bicycle.png";

    const finalMarker = finalPost ? (
      <Marker
      icon={{url: bikeIconUrl,
            origin: new window.google.maps.Point(0, 0),
            anchor:  new window.google.maps.Point(30, 30),
            scaledSize:  new window.google.maps.Size(60, 60)}}
        position={{lat: finalPost.lat, lng: finalPost.long}}
        key={finalPost.ID}
        zIndex={1000}
        onClick={this.props.onMarkerClick.bind(this, finalPost, otherPosts.length)}
        draggable={this.canDragMarker(finalPost)}
        onDragEnd={(e) => this.onMarkerDragEnd(e, finalPost)}
      ></Marker>
    ) : null;

    return (
        <GoogleMap
          mapContainerStyle={{height: "100%"}}
          center={this.props.mapCenter}
          zoom={this.state.mapZoom}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            gestureHandling: "greedy"}}
        >

          {locationMarker}
          {markers}
          {finalMarker}

        </GoogleMap>
       );
  }
}


export default React.memo(MapContainer)
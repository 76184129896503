import React from "react";

import { Grid, Typography, ButtonGroup } from "@material-ui/core";
import EmojiButton from "./EmojiButton";
const MessageContents = (props) => {

  let content;
  const message = props.post.message;
  if (message && message.length > 0) {
    content = (<><Grid item>
                  <Typography gutterBottom variant="h5" component="h2">
                    {message}
                  </Typography>
                </Grid>
                <Grid item container direction="row" justify="flex-end">
                  <Typography gutterBottom variant="h5" component="h2">
                    - {props.post.User.full_name}
                  </Typography>
                </Grid>
              </>)
  } else {
    content = (
      <Grid item container direction="row">
        <Typography gutterBottom variant="h5" component="h2">
          {props.post.User.full_name} wanted you to see this:
        </Typography>
      </Grid>
    )
  }

  // const spacer = props.useSpacer ?  : ""
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="baseline"
    >
      {content}
      <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
              <EmojiButton emoji="🚲" post={props.post} onEmojiUpdate={props.onEmojiUpdate}></EmojiButton>
              <EmojiButton emoji={"❤️"} post={props.post} onEmojiUpdate={props.onEmojiUpdate}></EmojiButton>
              <EmojiButton emoji="😔" post={props.post} onEmojiUpdate={props.onEmojiUpdate}></EmojiButton>
              <EmojiButton emoji="🤯" post={props.post} onEmojiUpdate={props.onEmojiUpdate}></EmojiButton>
              <EmojiButton emoji="💩" post={props.post} onEmojiUpdate={props.onEmojiUpdate}></EmojiButton>
            </ButtonGroup>
      <Grid item container direction="row" justify="flex-end">
        <div style={{height: "66px"}}></div>
      </Grid>
    </Grid>
  )
}

export default MessageContents;
import PublicClient from "./public";
import AuthClient from "./authd";
import { retrieveCredentials } from "../auth";

/**
 * Returns an instance of the client for the public API
 */
export const getPublicClientInstance = () => {
  return new PublicClient();
}

/**
 * Returns an instance of the auth client, loaded
 * with the currently stored credentials
 */
export const getAuthdClientInstance = (username, password) => {
  if (!username || !password) {
    try {
      const credentials = retrieveCredentials();
      username = credentials.username;
      password = credentials.password;
    } catch(e) {}
  }

  return new AuthClient(username, password);
}
import React from "react";

import { Fab } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import "./styles.css";

export default (props) => {
  return (
    <div className={"SettingsButton"}>
      <Fab color="primary" onClick={props.onClick}>
        <SettingsIcon />
      </Fab>
    </div>
  );
}
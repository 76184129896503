import React from "react";

import { Fab } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import "./styles.css";
import { showIfIsLoggedIn } from "../../../showIfIsLoggedIn";

export default class DeletePhotoButton extends React.Component {

  render() {
    return showIfIsLoggedIn(<div className={"DeletePhotoButton"}>
        <Fab color="secondary"
             onClick={(e) => {
               e.stopPropagation();
               this.props.onClick()
              }}
             size="small">
          <DeleteIcon />
        </Fab>
      </div>, <></>);
  }
}
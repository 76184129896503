import React, { Component } from 'react';
import { LoadScript } from '@react-google-maps/api'

import PostCreationDialog from "./components/PostCreationDialog";
import SignInDialog from "./SignInDialog";
import MapContainer from "./MapContainer";

import { getPublicClientInstance } from "./client";

import 'react-dropzone-uploader/dist/styles.css';
import {getAuthdClientInstance} from './client';
import {clearCredentials} from "./auth";
import SettingsButton from "./components/SettingsButton";
import { MenuItem, Snackbar } from '@material-ui/core';

import CommentsListDialog from "./components/CommentsDialog";
import CommentCreationDialog from "./components/CommentCreationDialog";
import MainMenu from './components/MainMenu';
import PostCardDialog from "./components/PostCardDialog";
import CurrentUserAvatar from "./components/CurrentUserAvatar";
import AddPostButton from "./components/AddPostButton";
import AddCommentButton from "./components/AddCommentButton";

import { Alert } from "./components/Alert";
import { getCurrentPostFromPath, setPathToPostsRoot, setPathToPost, getUrlForPostId } from './routing';
import "./App.css";
import { copyToClipboard, removeFromArray, replacePostInArray } from './utils';
import ViewControls from './components/ViewControls';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-169017482-1');


const DEFAULT_CENTER_LAT = 40.728476;
const DEFAULT_CENTER_LONG = -111.869332;

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      signInDialogOpen: false,
      isLoggedIn: false,
      postCreationDialogOpen: false,
      currentUser: undefined,
      drawerOpen: false,
      shouldCluster: true,
      shouldShowDeleteButtons: false,
    }
  }

  async listPosts() {
    try {
      const response = await getPublicClientInstance().listPosts()
      const posts = response.data.posts
      const shownPosts = this.getFilteredPosts(posts, this.state.showPostsFrom)
      this.setState({
        posts: posts,
        filteredPosts: shownPosts,
      });
      return posts;
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount() {
    const posts = await this.listPosts();
    const users = await getPublicClientInstance().listUsers();
    const currentPostFromURI = getCurrentPostFromPath();
    if (!currentPostFromURI) {
      setPathToPostsRoot();
      if (posts.length > 0) {
        const lastPost = posts[posts.length - 1];
        this.setMapLocation(lastPost.lat, lastPost.long);
      } else {
        this.setDefaultMapLocation();
      }
    } else {
      const selectedPost = posts.find(p => p.ID === currentPostFromURI);
      this.setCurrentPost(selectedPost);
    }
    try {
      const resp = await getAuthdClientInstance().me()
      const initState = {
        isLoggedIn: true,
        currentUser: resp.data.user,
      }
      this.setState(initState);
    } catch(error) {
      this.setState({isLoggedIn: false})
    }

    const initState = {
      showPostsFrom: {},
    }

    for (let user of users) {
      initState.showPostsFrom[user.full_name] = true;
    }
    this.setState(initState);
  }

  /**
   * Post dialog handlers
   */

  openPostCreationDialog = () => {
    this.setState({
      postCreationDialogOpen: true,
      currentlyEditedPost: undefined,
    });
  }

  handleCancelPost = () => {
    this.setState({ postCreationDialogOpen: false });
  }

  handlePostCreation = () => {
    this.listPosts();
  }

  handlePostEditEdited = (newPost) => {
    this.setState({
      selectedPost: newPost,
    });
  }

  async handlePhotoDelete(photo)  {
    await getAuthdClientInstance().deletePhoto(this.state.selectedPost.ID, photo.ID);
    this.setState({
      selectedPost: {
        ...this.state.selectedPost,
        Photos: removeFromArray(this.state.selectedPost.Photos, p => p.ID === photo.ID)
      }
    });
  }

  /**
   * Login Handlers
   */

  showSignInDialog = () => {
    this.setState({signInDialogOpen: true})
  }

  handleSignInClose = () => {
    this.setState({signInDialogOpen: false})
  }

  handleLogout = () => {
    clearCredentials()
    this.setState({
      isLoggedIn: false,
      currentUser: undefined,
    });
  }

  handleSignInError = () => {
    this.setState({
      alertShown: true,
      alertMessage: "Hmmm.. that username or password is incorrect"
    })
  }

  onSuccessfulSignIn = (user) => {
    this.setState({
      currentUser: user,
      isLoggedIn: true,
      signInDialogOpen: false,
    })
  }

  /**
   * Current Post Helpers
  */

  handlePostShowClose() {
    this.clearCurrentPost()
    setPathToPostsRoot();
  }

  async handleMarkerClick(post) {
    const postFromRemote = await getPublicClientInstance().getPost(post.ID);
    this.setCurrentPost(postFromRemote)
  }

  setCurrentPost(post) {
    const idx = this.state.filteredPosts.findIndex((p) => post.ID === p.ID);
    this.setState({
      selectedPost: post,
      selectedPostIdx: idx,
    });
    this.setMapLocation(post.lat, post.long)
    setPathToPost(post.ID)
  }

  setMapLocation(lat, lng) {
    this.setState({
      mapCenter: {
        lat,
        lng,
      }
    });    
  }

  setDefaultMapLocation() {
    this.setMapLocation(DEFAULT_CENTER_LAT, DEFAULT_CENTER_LONG);
  }

  /**
   * Given a post, finds it in the list of retrieved posts
   * and filtered posts, and replaces the old one with the new one
   * Used in the case that a post is updated and avoids relisting
   * all the posts
   * 
   * @param {} post 
   */
  replacePostWithUpdatedPost(post) {
    const updatedPostList = replacePostInArray(this.state.posts, post);
    const updatedFilteredPostList = replacePostInArray(this.state.filteredPosts, post);
    this.setState({
      posts: updatedPostList,
      filteredPosts: updatedFilteredPostList,
    });
  }

  goToNextPost() {
    if (this.state.selectedPostIdx < this.state.filteredPosts.length - 1) {
      this.setCurrentPost(this.state.filteredPosts[this.state.selectedPostIdx + 1]);
    }
  }

  goToPrevPost() {
    if (this.state.selectedPostIdx > 0) {
      this.setCurrentPost(this.state.filteredPosts[this.state.selectedPostIdx - 1]);
    }
  }

  clearCurrentPost() {
    this.setState({
      selectedPost: undefined
    });
  }

  async handlePostDelete(postId) {
    try {
      await getAuthdClientInstance().deletePost(postId);
    } catch (e) {
      this.handleNetworkError(e);
      return;
    }
    this.clearCurrentPost();
    this.listPosts();
  }

  handleOpenEdit(post) {
    this.setState({
      currentlyEditedPost: post,
      postCreationDialogOpen: true,
    });
  }

  handlePostShare(postId) {
    copyToClipboard(getUrlForPostId(postId));
    this.setInfoMessage("URL for post copied to clipboard");
  }

  /**
   * Error bar helpers
   */

  setErrorMessage(message) {
    this.showAlert(message, "error")
  }

  setSuccessMessage(message) {
    this.showAlert(message, "success")
  }

  setInfoMessage(message) {
    this.showAlert(message)
  }

  showAlert(message, severity = "info") {
    this.setState({
      alertShown: true,
      alertSeverity: severity,
      alertMessage: message
    });
  }

  handleErrorClose() {
    this.setState({
      alertShown: false,
    });
  }

  handleNetworkError(error) {
    this.setErrorMessage(`${error.response.status} - ${error.response.data.message}`);
  }

  /**
   * Control Handlers
   */

  handleDragToggleChange(event, newVal) {
    this.setState({
      canDrag: newVal
    })
  }

  handleMarkerDrag(post) {
    const postIdx = this.state.posts.findIndex(p => p.ID === post.ID);
    const postsCopy = this.state.posts.slice();
    postsCopy[postIdx] = post;

    const filteredPostsCopy = this.getFilteredPosts(postsCopy, this.state.showPostsFrom);

    this.setState({
      posts: postsCopy,
      filteredPosts: filteredPostsCopy,
    });
  }


  getFilteredPosts(posts, usersToShow) {
    const selectedUsers = [];
    if (usersToShow) {
      for (let userName in usersToShow) {
        if (usersToShow[userName]) {
          selectedUsers.push(userName);
        }
      }

      return posts.filter(p => selectedUsers.includes(p.User.full_name));
    } else {
      return posts;
    }
  }

  handleUserSwitch(event){
    const oldUsers = this.state.showPostsFrom;
    const usersToShowPostsFrom = { ...oldUsers, [event.target.name]: event.target.checked }

    const shownPosts = this.getFilteredPosts(this.state.posts, usersToShowPostsFrom)

    this.setState({ showPostsFrom: usersToShowPostsFrom, filteredPosts: shownPosts });
  };

  handleDrawerClose() {
    this.setState({drawerOpen: false});
  }

  handleSettingsClick() {
    this.setState({drawerOpen: true});
  }

  handleClusterChange(event) {
    this.setState({ shouldCluster: event.target.checked })
  }

  handleShouldShowDeleteChange(event) {
    this.setState({ shouldShowDeleteButtons: event.target.checked })
  }

  handleEmojiUpdate(post) {
    this.setCurrentPost(post);
    this.replacePostWithUpdatedPost(post);
  }

  handleCommentSubmit() {
    this.setSuccessMessage("Thanks for your comment!");
  }

  handleCommentModalClose() {
    this.setState({
      commentCreatorOpen: false,
    });
  }

  openCommentCreationDialog() {
    this.setState({
      commentCreatorOpen: true,
    });
  }

  async openCommentsList() {
    const client = getAuthdClientInstance();
    const comments = await client.listComments();
    this.setState({
      commentsListOpen: true,
      comments
    });
  }

  closeCommentsList() {
    this.setState({
      commentsListOpen: false,
    });
  }

  render() {
    const isLoggedIn = this.state.isLoggedIn;
    const menuItems = [];

    if (isLoggedIn) {
      menuItems.push(
        <MenuItem key={"logout"} onClick={this.handleLogout}>Logout</MenuItem>,
      );
      menuItems.push(
        <MenuItem key={"showComments"} onClick={this.openCommentsList.bind(this)}>Comments</MenuItem>,
      )
    } else {
      menuItems.push(
        <MenuItem key={"login"} onClick={this.showSignInDialog}>Login</MenuItem>,
      );
    }

    const currentUser = this.state.currentUser ?
      <CurrentUserAvatar user={this.state.currentUser}></CurrentUserAvatar> : "";

    const addPostButton = isLoggedIn ? <AddPostButton onClick={this.openPostCreationDialog.bind(this)}/> : <AddCommentButton onClick={this.openCommentCreationDialog.bind(this)}/>;

    const postCreationDialog = this.state.postCreationDialogOpen ? (        <PostCreationDialog
      open={this.state.postCreationDialogOpen}
      post={this.state.currentlyEditedPost}
      onClose={this.handleCancelPost.bind(this)}
      onPostCreated={this.handlePostCreation.bind(this)}
      onPostEdited={this.handlePostEditEdited.bind(this)}
      handleNetworkError={this.handleNetworkError.bind(this)}
      ></PostCreationDialog>) : "";

    const commentsListDialog = this.state.isLoggedIn && this.state.commentsListOpen ? <CommentsListDialog
      open={this.state.commentsListOpen}
      comments={this.state.comments}
      onClose={this.closeCommentsList.bind(this)}
      /> : "";
    
    const mapContainer = this.state.posts ?
      <MapContainer
        posts={this.state.filteredPosts}
        activePost={this.state.selectedPost}
        onMarkerClick={this.handleMarkerClick.bind(this)}
        showMarker={this.state.isLoggedIn}
        canDrag={this.state.canDrag}
        onDrag={this.handleMarkerDrag.bind(this)}
        currentUser={this.state.currentUser}
        askForLocation={this.state.isLoggedIn}
        shouldCluster={this.state.shouldCluster}
        mapCenter={this.state.mapCenter}
      /> : ""

    return (
      <LoadScript googleMapsApiKey="AIzaSyC50oepEQT22A8saXruWe9n2FolZ_oNx3o">

        <SettingsButton onClick={this.handleSettingsClick.bind(this)}></SettingsButton>

        {this.state.showPostsFrom && <ViewControls
          users={this.state.showPostsFrom}
          open={this.state.drawerOpen}
          handleUserChange={this.handleUserSwitch.bind(this)}
          onClose={this.handleDrawerClose.bind(this)}
          showLoggedInControls={Boolean(this.state.currentUser)}
          canDrag={this.state.canDrag}
          handleDragToggleChange={this.handleDragToggleChange.bind(this)}
          shouldCluster={this.state.shouldCluster}
          handleClusterChange={this.handleClusterChange.bind(this)}
          shouldShowDeleteButtons={this.state.shouldShowDeleteButtons}
          handleShouldShowDeleteChange={this.handleShouldShowDeleteChange.bind(this)}
        ></ViewControls>}

        <PostCardDialog
          open={Boolean(this.state.selectedPost)}
          onClose={this.handlePostShowClose.bind(this)}
          onShare={this.handlePostShare.bind(this)}
          post={this.state.selectedPost}
          handleEdit={this.handleOpenEdit.bind(this)}
          handleDelete={this.handlePostDelete.bind(this)}
          handlePhotoDelete={this.handlePhotoDelete.bind(this)}
          handleNetworkError={this.handleNetworkError.bind(this)}
          onEmojiUpdate={this.handleEmojiUpdate.bind(this)}
          shouldShowDeleteButtons={this.state.shouldShowDeleteButtons}
          onNextClick={this.goToNextPost.bind(this)}
          onPrevClick={this.goToPrevPost.bind(this)}
        ></PostCardDialog>

        <CommentCreationDialog
          open={this.state.commentCreatorOpen}
          onClose={this.handleCommentModalClose.bind(this)}
          handleNetworkError={this.handleNetworkError.bind(this)}
          handleSubmit={this.handleCommentSubmit.bind(this)} />

        {postCreationDialog}
        {commentsListDialog}

        <SignInDialog
          dialogOpen={this.state.signInDialogOpen}
          handleSignInClose={this.handleSignInClose}
          handleSignInError={this.handleSignInError}
          onSuccessfulSignIn={this.onSuccessfulSignIn.bind(this)} />

        {mapContainer}

        <MainMenu
          icon={currentUser}>
          {menuItems}
        </MainMenu>


        {addPostButton}

        <Snackbar
          open={this.state.alertShown}
          autoHideDuration={6000}
          onClose={this.handleErrorClose.bind(this)}>
          <Alert severity={this.state.alertSeverity}>
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
      </LoadScript>
    );
  }
}

export default App
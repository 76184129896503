import React, { Component } from 'react';
import { Dialog,
         DialogTitle,
         DialogContent,
         DialogContentText,
         TextField,
         Button,
         DialogActions } from "@material-ui/core";
import { getAuthdClientInstance } from './client';
import { persistCredentials } from "./auth";

export default class SignInDialog extends Component {
  constructor(props){
    super(props)
    this.state = {
      open: false,
      errorOpen: false,
      username: "",
      password: "",
      submitDisabled: true,
    }
  }

  componentDidUpdate(previousProps) {
    if(previousProps.dialogOpen !== this.props.dialogOpen) {
      this.setState({open: this.props.dialogOpen})
    }
  }

  async handleSignInClick() {
    try {
      const resp = await getAuthdClientInstance(
        this.state.username,
        this.state.password
      ).me();
      persistCredentials(this.state.username, this.state.password)
      this.props.onSuccessfulSignIn(resp.data.user)
    } catch(e) {
      this.props.handleSignInError();
    }
  }

  handleUsernameChange(e) {
    this.setState({
      username: e.target.value,
      submitDisabled: !(e.target.value.length > 0 && this.state.password.length > 0),
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
      submitDisabled: !(e.target.value.length > 0 && this.state.username.length > 0),
    });
  }

  render() {
  	return (

      <Dialog open={this.state.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Sign In</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To upload posts, please sign in.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Username"
            type="text"
            fullWidth
            onChange={this.handleUsernameChange.bind(this)}
          />
          <TextField

            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            onChange={this.handlePasswordChange.bind(this)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={this.state.submitDisabled} onClick={this.handleSignInClick.bind(this)} color="primary">
            Sign In
          </Button>
        </DialogActions>
      </Dialog>
  	);
  }
}
const moment = require("moment");

export const formatDateString = (timestamp) => {
  return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
}

export const getCurrentPosition = async (options = {}) => {
  const pos = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
  return {
    lat: pos.coords.latitude,
    long: pos.coords.longitude,
  }
};

export const isPhotoVideo = (photo) => {
  return photo.PublicURL.includes("mp4");
}


/**
 * Removes the first item matching the provided predicate
 * from the provided array
 *
 * @param {*} array
 * @param {*} predicate
 */
export const removeFromArray = (array, predicate) => {
  const itemIdx = array.findIndex(predicate);

  return [
      ...array.slice(0, itemIdx),
      ...array.slice(itemIdx + 1)];
}

export const getPostIndex = (postArray, post) => {
  return postArray.findIndex(postMatchingPredicate(post));
}

export const postMatchingPredicate = (post) => {
  return p => p.ID === post.ID;
}

export const replacePostInArray = (arr, post) => {
  return replaceByPredicate(arr, postMatchingPredicate(post), post);
}

export const replaceByPredicate = (arr, predicate, itemToInsert) => {
  const idx = arr.findIndex(predicate);
  arr[idx] = itemToInsert;
  return arr;
}

/**
 * Want to be able to copy to clipboard - shamelessly stolen
 *
 * https://www.30secondsofcode.org/blog/s/copy-text-to-clipboard-with-javascript
 * @param {*} str
 */
export const copyToClipboard = str => {
  var textarea = document.createElement('textarea');
  textarea.textContent = str;
  document.body.appendChild(textarea);

  var selection = document.getSelection();
  var range = document.createRange();
//  range.selectNodeContents(textarea);
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);

  console.log('copy success', document.execCommand('copy'));
  selection.removeAllRanges();

  document.body.removeChild(textarea);
};
import React, { useState } from "react";

import { Button } from "@material-ui/core";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


import { getPublicClientInstance } from "../../../client";

const EMOJI_MAP = {
    "❤️": "HeartCount",
    "😔": "DisappointedCount",
    "🤯": "WowCount",
    "🚲": "BikeCount",
    "💩": "PoopCount"
}
export default (props) => {
    const keyToUpdate = EMOJI_MAP[props.emoji];

    const theme = useTheme();
    const isSmallWindow = useMediaQuery(theme.breakpoints.down('xs'));
    const count = props.post[keyToUpdate]
    const emojiFontSize = isSmallWindow ? "1.2em" : "2em";
    const countFontSize = isSmallWindow ? "1.2em" : "1.5em";
    const countLeftMargin = isSmallWindow ? "6px" : "12px";

    const onClick = async () => {
        const postResponse = await getPublicClientInstance().updatePost(props.post.ID, { [keyToUpdate]: 10000 })
        const post = postResponse.data.post;
        props.onEmojiUpdate(post);
    }

    return (<Button onClick={onClick}>
            <div style={{fontSize: emojiFontSize}}>{props.emoji}</div>
            <div style={{fontSize: countFontSize, marginLeft: countLeftMargin}}>{count}</div>
            </Button>);
}
import "path";
import ReactGA from "react-ga";

const FORWARD_SLASH = "/";

const POSTS_URL_FRAGMENT = "posts";

export const getCurrentHashPath = () => {
  if (window.location.hash) {
    return window.location.hash.substring(1);
  } else {
    return FORWARD_SLASH;
  }
}

const splitPath = (path) => {
  return path.split(FORWARD_SLASH);
}

export const getCurrentPostFromPath = () => {
  return getPostFromPath(getCurrentHashPath());
}

export const getPostFromPath = (path) => {
  const split = splitPath(path);
  if (split.length !== 3) return undefined;
  if (split[1] !== POSTS_URL_FRAGMENT) return undefined;
  const maybePostId = parseInt(split[2]);
  if (!Number.isInteger(parseInt(maybePostId))) return undefined;
  return maybePostId;
}

export const setPathToPost = (postId) => {
  setPath(getPathForPostId(postId));
}

export const getPathForPostId = (postId) => {
  return `/posts/${postId}`;
}

export const setPathToPostsRoot = () => {
  setPath("/posts");
}

export const getUrlForPostId = (postId) => {
  return `${window.location.origin}/#${getPathForPostId(postId)}`;
}

export const setPath = (path) => {
  window.location.hash = `#${path}`;
  ReactGA.pageview(path);
}
import React from "react";
import "./styles.css";
import { CardActionArea } from "@material-ui/core";
import { isPhotoVideo } from "../../../utils";
import DeletePhotoButton from "./DeletePhotoButton";


export default class CardPhoto extends React.Component {

  isVideo() {
    return isPhotoVideo(this.props.photo);
  }

  render() {
    if (this.isVideo()) {
      return (
        <video controls width="100%">
          <source
            src={this.props.photo.PublicURL}
            type="video/mp4"
          ></source>

          Sorry, your browser doesn't support embedded videos.
        </video>
      )
    } else {
      return (
        <CardActionArea
          onClick={() => { this.props.onClick(this.props.photo) }}
          >
          {this.props.shouldShowDeleteButtons && <DeletePhotoButton onClick={() => { this.props.onDelete(this.props.photo) }}></DeletePhotoButton>}
          <img alt="I wish you could see this" src={this.props.photo.PublicURL} className={"PostImage"}  />
        </CardActionArea>
      );
    }
  }
}
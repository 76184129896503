import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Dialog, Grid, Fab, IconButton } from "@material-ui/core";

import PostCard from "../PostCard";
import CloseIcon from "@material-ui/icons/Close";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { showIfIsLoggedIn } from "../showIfIsLoggedIn";

import "./styles.css";

const PostCardDialog = (props) => {

  const [shouldShowNavs, setShouldShowNavs] = useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs')) // && props.post && props.post.Photos.length > 0;
  const classes = {}
  if (fullScreen) {
    classes.paperScrollPaper = "FullScreenRoot";
  }
  return (
    <div>
      {shouldShowNavs && props.open && <IconButton variant="outlined" color="secondary" classes={{root: "NextButton"}} onClick={props.onNextClick}>
        <ArrowForwardIcon fontSize="large"/>
      </IconButton>}

      {shouldShowNavs && props.open && <IconButton variant="outlined" color="secondary" classes={{root: "PreviousButton"}} onClick={props.onPrevClick}>
        <ArrowBackIcon fontSize="large"/>
      </IconButton>}
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullScreen={fullScreen}
        fullWidth
        classes={classes}
        maxWidth="sm">
          <div className={"Close"}>
            <Fab color="primary" onClick={props.onClose}>
              <CloseIcon />
            </Fab>
          </div>
          <Grid container
            direction="row"
            justify="flex-end"
            className={"Controls"}
            spacing={1}>
            {props.shouldShowDeleteButtons && showIfIsLoggedIn(
              <Grid item>
                <Fab color="secondary" onClick={() => { props.handleDelete(props.post.ID) }}>
                  <DeleteIcon />
                </Fab>
              </Grid>,
              ""
            )}
            {showIfIsLoggedIn(
              <Grid item>
                <Fab color="primary" onClick={() => { props.handleEdit(props.post) }}>
                  <EditIcon />
                </Fab>
              </Grid>,
              ""
            )}
            <Grid item>
              <Fab color="primary" onClick={() => { props.onShare(props.post.ID) }}>
                <ShareIcon />
              </Fab>
            </Grid>
          </Grid>



          <Grid container
                direction="column">
            <Grid item>
              { props.post ?
                <PostCard
                  post={props.post}
                  onEmojiUpdate={props.onEmojiUpdate}
                  onLightBoxOpen={() => {  setShouldShowNavs(false) }}
                  onLightBoxClose={() => {  setShouldShowNavs(true) }}
                  handleDelete={props.handleDelete}
                  handleClose={props.onClose}
                  handlePhotoDelete={props.handlePhotoDelete}
                  shouldShowDeleteButtons={props.shouldShowDeleteButtons}>
                </PostCard> : "" }
            </Grid>
          </Grid>
      </Dialog>
    </div>
  );
}

export default PostCardDialog;
import React, { Component } from 'react';

import { Dialog,
         DialogTitle,
         DialogContent,
         TextField,
         Button,
         DialogActions,
         Grid,
         CircularProgress } from "@material-ui/core";

import Uploader from "../Uploader";

import 'react-dropzone-uploader/dist/styles.css';
import { getCurrentPosition, removeFromArray } from '../../utils';
import { getAuthdClientInstance, getPublicClientInstance } from '../../client';

import "./styles.css";

const DONE_STATUS    = "done";
const REMOVED_STATUS = "removed";
const UPLOADING_STATUS = "uploading";
const ABORTED_STATUS = "aborted";

export default class PostCreationDialog extends Component {

  constructor(props) {
    super(props)

    const message = props.post ? props.post.message : "";
    this.state = {
      post: props.post,
      message,
      files: [],
      inProgressFiles: [],
    }
  }



  clearFiles() {
    this.setState({
      files: [],
      inProgressFiles: [],
    });
  }

  clearMessage() {
    this.setState({
      message: ""
    });
  }

  // receives array of files that are done uploading when submit button is clicked
  async handleSubmit() {
    this.setState({
      submitInProgress: true
    });
    const files = this.state.files;
    const { lat, long } = await getCurrentPosition();
    const client = getAuthdClientInstance();
    let resp;
    try {
      if (this.state.post) {
        resp = await client.updatePost(
          this.state.post.ID,
          { message: this.state.message },
        );
        if (resp.status === 401) {
          const error = new Error();
          error.response = resp;
          throw error;
        }
      } else {
        resp = await client.createPost(
          this.state.message,
          lat,
          long,
        );
      }
    } catch(e){
      this.props.handleNetworkError(e);
      this.setState({ submitInProgress: false })
      return;
    }
    const postId = resp.data.post.ID;

    for (let photo of files) {
      await client.createPhoto(
        postId,
        photo.meta.s3Key,
        photo.meta.s3Bucket,
      );
    }

    const post = await getPublicClientInstance().getPost(postId);
    if (this.props.post) {
      this.props.onPostEdited(post);
    } else {
      this.props.onPostCreated(post);
    }
    this.handleClose();
  }

  handleMessageChange(changeEvent) {
    const msg = changeEvent.target.value;
    const canSubmit = msg.length > 0;
    this.setState({
      ...this.state,
      message: msg,
      canSubmit,
    });
  }

  handleFileChange(fileWithMeta, status) {
    switch (status) {
      case DONE_STATUS:
        this.setState({
          files: [...this.state.files, fileWithMeta],
          inProgressFiles: removeFromArray(this.state.inProgressFiles, (s3Key) => {
            return s3Key === fileWithMeta.meta.s3Key;
          }),
        });
        break;
      case REMOVED_STATUS:
      case ABORTED_STATUS:
        // Exclude the matching file from the new files list state
        this.setState({
          files: removeFromArray(this.state.files, (f) => {
            return f.meta.s3Key === fileWithMeta.meta.s3Key;
          }),
          inProgressFiles: removeFromArray(this.state.inProgressFiles, (s3Key) => {
            return s3Key === fileWithMeta.meta.s3Key;
          }),
        });
        break;
      case UPLOADING_STATUS:
        this.setState({
          inProgressFiles: [...this.state.inProgressFiles, fileWithMeta.meta.s3Key],
        });
        break;
    }
  }

  canSubmit() {
    const messagePresent = this.state.message.length > 0;
    const noPhotosLoading = this.state.inProgressFiles.length === 0;
    const photosPresent = this.state.files.length > 0;
    return (messagePresent || photosPresent) && noPhotosLoading;
  }

  submitButtonText() {
    if (this.state.submitInProgress) {
      return "Submitting..."
    }
    const noPhotosLoading = this.state.inProgressFiles.length === 0;
    return noPhotosLoading ? "Submit" : "Uploading..."
  }

  async handleClose() {
    this.clearFiles();
    this.clearMessage();
    this.props.onClose();
    this.setState( {submitInProgress: false} );
  }

  render() {
    const title = this.state.post ? `Update Post ${this.state.post.ID}` : "Create A New Post"; 

    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="lg">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            direction="column">
            <Grid item>
              <TextField
              multiline
              fullWidth
              placeholder="Write a quick message"
              value={this.state.message}
              label="Message body"
              variant="filled"
              onChange={this.handleMessageChange.bind(this)}></TextField>
            </Grid>
            <Grid item>
              <Uploader
              handleChangeStatus={this.handleFileChange.bind(this)}
              ></Uploader>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>CANCEL</Button>
          <div className={"SubmitWrapper"}>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.canSubmit()}
              onClick={this.handleSubmit.bind(this)}
            >
              {this.submitButtonText()}
            </Button>
            {this.state.submitInProgress && <CircularProgress size={24} className={"SubmitProgress"} />}
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}